import React, { createContext, useState, useEffect, useMemo } from "react";
import defaultLogo from "./../images/logo.png";
import logo_marzo from "./../images/lxl_marzo.png";
import logo_lgbt from "./../images/lxl_lgbt.png";
import logo_patrio from "./../images/lxl_patrio.png";
import logo_cancer from "./../images/lxl_cancer.png";

export const LogoContext = createContext();

const logoData = [
    { url: logo_marzo, start_date: "2024-03-08", end_date: "2024-03-31", color_text: "#8137F2", second_color_text: "#8137F2", class: "pulse-blue" },
    { url: logo_lgbt, start_date: "2024-06-10", end_date: "2024-06-30", color_text: "white", second_color_text: "white", class: "pulse-rainbow" },
    { url: logo_patrio, start_date: "2024-09-01", end_date: "2024-09-30", color_text: "#006341", second_color_text: "#C8102E", class: "pulse-flag" },
    { url: logo_cancer, start_date: "2024-10-18", end_date: "2024-10-31", color_text: "#F3619A", second_color_text: "#F3619A", class: "pulse-pink" }
];


export const LogoContextProvider = ({ children }) => {
    const [logoToUse, setLogoToUse] = useState(defaultLogo);
    const [color_text, setColorText] = useState("green");
    const [logo_text, setLogoText] = useState("logo-texto");
    const [color_text_2, setColorText2] = useState("green");

    const currentLogo = useMemo(() => {
        const currentDate = new Date();
        return logoData.find(logo => {
            return (
                currentDate.getMonth() === new Date(logo.start_date).getMonth() &&
                currentDate.getDate() >= new Date(logo.start_date).getDate() &&
                currentDate.getDate() <= new Date(logo.end_date).getDate()
            );
        });
    }, []);

    useEffect(() => {
        if (currentLogo) {
            setLogoToUse(currentLogo.url);
            setColorText(currentLogo.color_text);
            setLogoText(currentLogo.class);
            setColorText2(currentLogo.second_color_text);
        } else {
            setLogoToUse(defaultLogo);
        }
    }, [currentLogo]);

    return (
        <LogoContext.Provider value={{ logoToUse, color_text, logo_text, color_text_2 }}>
            {children}
        </LogoContext.Provider>
    );
};