exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cita-js": () => import("./../../../src/pages/cita.js" /* webpackChunkName: "component---src-pages-cita-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instalaciones-js": () => import("./../../../src/pages/instalaciones.js" /* webpackChunkName: "component---src-pages-instalaciones-js" */),
  "component---src-pages-negocios-js": () => import("./../../../src/pages/negocios.js" /* webpackChunkName: "component---src-pages-negocios-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */)
}

