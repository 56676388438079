import * as React from "react"
import { LogoContextProvider } from "../context/logoContext"


const WrapRootElement = ({ children }) => (
  <LogoContextProvider>
    {children}
  </LogoContextProvider>
)

export default WrapRootElement
